<template>
  <div class="support container-fluid ">
    <h1 class="page-header primary">Support</h1>

    <div v-if="!isAuthenticated">
      <div class="f3">How to Login</div>
      Make sure you have a server properly configured by clicking "Configure
      Server" on the
      <router-link class="gray hover-gold" to="/login"> log in</router-link>
      page. You will also need to ensure that the administrator has previously
      created an account and that your username and password are correct. If you
      are unsure what server address to enter, please contact support using the
      information below, then try again to
      <router-link class="gray hover-gold" to="/login"> log in</router-link>.
      <p class="pa1" />
    </div>

    <div class="f3">Contact Information</div>
    <p class="tracked">
      <a
        id="supportContactID"
        class="gray hover-gold"
        href="mailto:support@edxtech.com"
        >support@edxtech.com</a
      >
    </p>
    <p class="tracked">
      <a
        id="websiteContactID"
        class="gray hover-gold"
        href="https://www.edxtech.com/contact/"
        >https://www.edxtech.com/contact/</a
      >
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';

export default {
  name: 'Support',
  computed: mapGetters(['isAuthenticated']),
  components: {},
  created() {},
  mounted() {
    this.doThemeThings();
  },
  methods: {
    doThemeThings() {
      let websiteContact = document.getElementById('websiteContactID');
      let supportContact = document.getElementById('supportContactID');

      switch (process.env.VUE_APP_THEME) {
        case 'edxw':
          websiteContact.innerHTML = 'https://www.edx.com/contact';
          supportContact.innerHTML = 'support@edx.com';
          break;
        default:
          websiteContact.innerHTML = 'https://www.edxtech.com/contact';
          supportContact.innerHTML = 'support@edxtech.com';
      }
    }, // end doThemeThings
  },
};
</script>
